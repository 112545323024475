.block.display-posts {

	padding:0;

	&.featured {
		.item {
			margin-bottom:100px;
		}
		.container {
			position: relative;
			&:after {
				content:'';
				background-image:url('../images/background-cactus.svg');
			    position: absolute;
			    bottom:10px;
			    left:-51px;
			    right: 0px;
			    height: 89px;
			    width: 335px;
			}
		}
	}
	.item {
		margin-bottom:50px;
		.blog-thumb {
			position: relative;
			img {
				border-radius:4px 4px 0 0;
				display: block;
			}
			.featured {
				position: absolute;
				right:1%;
				bottom:0;
				background-color:#fff;
				color:$primary;
				font-family: $hFont;
				text-align: center;
				border-radius:4px 4px 0 0;
				padding: 1px 17px;
				font-size: 18px;

			}
		}
		time {
			margin:10px auto 5px;
			max-width:95%;
			text-align: center;
			display: block;
			font-size:12px;
			font-weight: 300;
			line-height:14px;
			position:relative;
			&:after, &:before {
				content:'';
				position:absolute;
				background-color:#cac9c9;
				height: 1px;
				transform:translateY(-50%);
				width:35%;
				top:50%;
			}
			&:before {
				left:0;
			}
			&:after {
				right:0;
			}
		}
		h3.entry-title {
			margin:0;
			font-family: $pFont;
			font-size:18px;
			font-weight: 800;
			line-height:26px;
			text-decoration: none;
			color:$primary;
			margin-left:1%;
		}
		.excerpt {
			margin-left: 1%;
			margin-bottom:10px;
		}
		footer {
			text-align: right;
			a.read-more {
				color:$primary;
				transition:$transition;
				font-weight: 500;
				text-decoration: none;
				margin-right:1%;
				&:hover {
					color:$secondary
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		&.featured {
			.container {
				&:after {
				    bottom:10px;
				    left:-85px;
				    right: 0px;
				    height: 101px;
				    width: 381px;
				}
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}