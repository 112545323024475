.block.careers-list {

	padding-top:75px;

	.container {
		position: relative;
		&:after {
			content:'';
			background-image:url('../images/background-rocks.svg');
		    position: absolute;
		    top:35px;
		    right: 0px;
		    height: 54px;
		    width: 187px;
		}
		.row {
			position: relative;
			z-index: 1;
		}
	}

	.section-title {
		margin-bottom:60px;
	}

	.btn-container {
		text-align: right;
	}

	.item {
		padding:10px 0;
		margin-bottom:10px;
		position:relative;
		&:after {
			content:'';
			position:absolute;
			height:1px;
			width:calc(100% - 30px);
			left:50%;
			transform:translateX(-50%);
			bottom:0px;
			background-color:#cac9c9;
		}
		h3 {
			font-family: $pFont;
			color:$secondary;
			font-weight: 800;
			margin:0 0 5px 0;
		}
		h4 {
			font-family: $pFont;
			margin:0;
		}
		.btn {
			padding:4px 9px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.section-title {
			margin-bottom:40px;
		}

		.container {
			&:after {
			    top:100px;
			    height: 123px;
			    width: 427px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}