.numeric-pagination {
	text-align: center;
	width: 100%;
	padding-bottom:75px;
	a {
		text-decoration: none;
		color:$primary;
		transition: $transition;
		&.prev, &.next {
			color:$primary;
			&:hover {
				color:$grey-med;
			}
		}
		&.prev {
			margin-right: 20px;
		}
		&.next {
			margin-left: 20px;
		}
	}

	.page-numbers {
		color:$grey-dark;
		font-weight: bold;
		font-size: 18px;
		margin:0 6px;
		&.current {
			color: $secondary;
		}
		&:hover:not(.current) {
			color:$secondary;
		}
	}
}