.error404 {
	margin-top: calc(#{$header-mobile-height});

	.admin-bar & {
		margin-top: calc(32px + #{$header-mobile-height});
	}

	.content {
		padding-top:120px;
		text-align: center;
		padding-bottom:80px;
		.title {
			position:relative;
			h1 {
				color:#fff;
				position:relative;
				z-index:2;
				margin:0;
			}
			i {
				position:absolute;
				left:50%;
				top:0%;
				transform:translate(-50%, -50%);
				color:$secondary;
				z-index:1;
				font-size: 150px;
			}
		}
		h2 {
			margin-top:82px;
		}
		.btn {
			margin-top:20px;
		}
	}


	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		margin-top: calc(8px + #{$header-desktop-height});

		.content {
			.title {
				h1 {
					font-size: 63px;
				}
				i {
					font-size: 260px;
				}
			}
			h2 {
				margin-top:150px;
				font-size: 45px;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}