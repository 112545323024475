.menus.mobile {
	position: fixed;
	height: calc(100% - #{$header-mobile-height});
	width: 100%;
	z-index: 100;
	display: block;
	background-color: #F2F2F2;;
	top: $header-mobile-height;
	transform:translateX(100%);
	transition: $transition;
	overflow-y: auto;
	padding: 10px 20px 80px;
	.menu-open & {
		transform: translateX(0);
	}

	.admin-bar & {
		top: calc(46px + #{$header-mobile-height});
	}

	nav {
		ul {
			@include list();
			li {
				a {
					display: block;
					text-decoration:none;;
				}

				&:first-child, &:last-child {
					a {
						@extend .btn;
						padding:10px 41px!important;
						display:block!important;
						font-size:16px!important;
					}
					margin:15px 0;
				}
				&:first-child {
					a {
						background-color: $secondary!important;
						&:hover {
							background-color: $primary!important;
						}
					}
				}
		
			}
		}
		&.nav-primary {
			ul {
				li {
					&.menu-item-has-children {
						position: relative;
						&:before {
							content:'';
							position: absolute;
							right: 20px;
							top: 27px;
							transform: translateY(-50%) rotate(90deg);
							@include iconFont;
							content: "\";
							color: $primary;
							font-size: 13px;
							transition: $transition;
						}
						&:hover {
							&:before {
								color: #fff;
							}
						}
						&.active {
							&:before {
								margin-top: -4px;
								transform: rotate(-90deg);
								color:$secondary;
							}
						}
					}
					.sub-menu {
						max-height: 0;
						overflow: hidden;
						transition: $transition;
						li {
							a {
								font-size: 13px;
								padding-left: 30px;
							}
						}
					}
					a {
						font-weight: 500;
						font-size: 20px;
						padding:5px 10px;
						border-bottom:1px solid rgba(#928D84, 0.6);
						transition: $transition;
						margin-bottom:10px;
						color:$primary;
						&:hover {
							color:#fff;
							background-color:$primary;
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
