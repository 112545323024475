body .wrap .gform_wrapper .top_label div.ginput_container {
	margin-top:0;
}

.wrap .gform_wrapper {
	.gfield {
		margin-top:30px;
	}

	label.gfield_label {
		font-weight: 600;
		.gfield_required {
			display: none;
		}
	}

	/** 
	 *	Input, select and textarea styles
	 */

	input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
	select,
	textarea {
		background-color: #fff;
		line-height: 1em;
		border: 1px solid $primary;
		border-radius: 6px;
		&::placeholder {
			color:rgba(0,0,0,0.3);
		}
	}

	input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]),
	select {
		height: 30px;
		padding: 0px 10px;
	}

	textarea {
		padding: 10px;
	}

	/**
	 *	Submit Button
	 */

	.gform_footer {
		text-align: center;
		.btn {
			padding: 14px 78px;
		}
	}

	/**
	 *	Validation Styles
	 */

	div.validation_error {
		border: 0;
		background-color: $error;
		color: #fff;
		font-weight: 500;
		padding: 10px 10px;
		display: block;
		margin-top: 30px;
		margin-bottom: 0;
		line-height: 1.5em;
		border-radius: 15px;
	}

	.gfield_error .gfield_label {
		color:#fff;
	}

	li.gfield.gfield_error {
		background-color: $error;
		padding: 0px 10px;
		border-radius: 15px;
		.ginput_container {
			padding-right: 10px;
		}
		.validation_message {
			color: #fff;
			font-weight: normal;
			line-height: 1.5em;
			padding-top: 6px;
			padding-bottom: 5px;
			font-size: 12px;
			text-align: center;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
