.wrap {

	font-family: $pFont;
	font-size: 16px;
	line-height: 26px;
	font-weight: 300;

	h1,h2,h3,h4,h5,h6 {
		font-family: $hFont;
		line-height: 1em;
		font-weight: 500;
	}


	p {
		&:first-child {
			margin-top: 0;
		}
	}
	

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
	

		h1 {
			
		}
		h2 {
			font-size:25px;
			line-height:30px;
		}
		h3 {
			font-size:20px;
		}
		h4 {
			font-size: 16px;
			
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}