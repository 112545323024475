.block.fullwidth-video {
	overflow: hidden;
	padding:0;
	.container {
		@include media-breakpoint-down(sm) {
			padding:0;
		}
	}

	.intro-text {
		h4 {
			color:$secondary;
			font-family: $pFont;
			font-size: 20px;
			margin:37px 0 20px;
			padding-bottom: 10px;
			border-bottom: 1px solid #c9c7c2;
			font-weight: 600;
		}
		h4, .description {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
	
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}