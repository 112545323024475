
@font-face {
  font-family: "iconFont";
  src:  url('../fonts/iconFont.woff2?1565714815') format('woff2'),
        url('../fonts/iconFont.woff?1565714815') format('woff');
  font-weight: normal;
  font-style: normal;
}


@mixin iconFont {

  font-family: 'iconFont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}





[class^="icon-"], [class*=" icon-"] {
  
  &:before {@include iconFont;}

}



.icon-shield:before {
  content: "\"
}
.icon-lock:before {
  content: "\"
}
.icon-play:before {
  content: "\"
}
.icon-chevron:before {
  content: "\"
}
.icon-twitter:before {
  content: "\"
}
.icon-facebook:before {
  content: "\"
}
.icon-phone:before {
  content: "\"
}
.icon-mail:before {
  content: "\"
}
.icon-starburst:before {
  content: "\"
}
.icon-chevron-thin-left:before {
  content: "\"
}
.icon-chevron-thin-right:before {
  content: "\"
}