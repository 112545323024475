.menu-trigger {
	display: block;
	position: absolute;
	right: 20px;
	top: 2px;
	width: 28px;
	height: 45px;
	color: #fff;
	font-size: 0.625em;
	text-transform: uppercase;
	text-decoration: none;
	z-index: 4;
	transition: 0.3s;
	.bar {
		position: absolute;
		left: 0px;
		background-color:$light-teal;
		height: 4px;
		display: block;
		transition: right 0.3s ease, transform 0.3s ease;
		transform: rotate(0deg);
		transform-origin: center center;
		&.bar-1 {
			right: 0px;
			top: 12px;
		}
		&.bar-2 {
			right: 0px;
			top: 20px;
		}
		&.bar-3 {
			right: 0px;
			top: 28px;
		}
	}
	.menu-open & {
		.bar-2,
		.bar-3 {
			width: 24px;
			top: 50%;
			left: 50%;
			margin: -3px 0 0 -12px;
			right: auto !important;
		}
		.bar-2 {
			transform: rotate(45deg);
		}
		.bar-3 {
			transform: rotate(-45deg);
		}
		.bar-1 {
			display: none;
		}
	}
}