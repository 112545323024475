.btn {
	display: inline-block;
	text-decoration: none;
	transition: $transition;
	text-align: center;
	position: relative;
	padding: 14px 41px;
	outline:none;
	border:none;
	appearance:none;
	text-transform: uppercase;
	font-weight: 600;
	cursor: pointer;
	border-radius:4px;
	font-weight: 500;
	font-family:$oswald;
	background-color:$light-teal;
	color:#fff;
	letter-spacing:0.5px;
	&:hover {
		background-color:$secondary;
	}
	&.skinny {
		padding:5px 54px;
	}



	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}

}


