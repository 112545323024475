.block.video-callout {
	padding:75px 0;
	.info {
		.btn {
			margin-top:10px;
			display: none;
		}
	}
	.video {
		text-align: center;
		@include media-breakpoint-down(sm) {
			padding:0;
		}
		.btn {
			margin-top:30px;
			padding:5px 21px;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		.info {
			.btn {
				display: inline-block;
			}
		}
		.video {
			.btn {
				display: none;
			}
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}