.video-container {
	position:relative;
	padding-bottom:56.25%;
	.poster, iframe {
		position: absolute;
		top:0;
		left:0;
		height:100%;
		width:100%;
	}
}
.poster {
	z-index: 50;
	background: {
		size:cover;
		position:center;
	}
	&.playing-video {
		display:none;
	}
	.play-video {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		font-size:6vw;
		color:#fff;
		text-decoration: none;
		transition:$transition;
		&:hover {
			color:$secondary;
			font-size:6.5vw;
		}
	}
}
