.block.interior-banner {
	padding-top: 60px;
	padding-bottom: 35px;
	background: {
		repeat: repeat;
		position: center;
		color: $grey-light;
		size: contain;
	}
	text-align: center;

	.section-title {
		font-weight: 400;
	}
	h2 {
		margin-top: 10px;
	}

	.icon {
		img {
			max-width: 150px;
			width:100%;
		}
	}

	&.full_width {
		.icon {
			margin-top: 20px;
			img {
				max-width: 400px;
				width:100%;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.icon {
			margin-top: -15px;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
