.block {

	padding-top:75px;
	padding-bottom:75px;


	.centered {
		text-align:center;
	}

	.section-title {
		text-align: center;
		display: block;
		font-weight:400;

	}

	h2 {
		margin-top:15px;
		text-align: center;
		display:block;
		margin-bottom:25px;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-top:130px;
		padding-bottom:130px;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}