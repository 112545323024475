.block.quote-name {
	padding-top:68px;
	padding-bottom:150px;
	.container {
		position: relative;
		.cloud {
			background-image:url('../images/clouds_mirror.svg');
		    position: absolute;
		    top:-68px;
		    left: 0;
		    height: 63px;
		    width: 181px;
		}
		.sunset {
		    position: absolute;
		    bottom:-132px;
		    right: 0;
		    height: 118px;
		    width: 261px;
		    .sun {
				background: {
					repeat:no-repeat;
					position:center;
					image:url('../images/sun.svg');
				}
			    position: absolute;
			    left: 0;
			    z-index: -1;
			    bottom: 59px;
			    height: 68px;
			    width: 159px;
		    }
		    .horizon {
				background-image:url('../images/sunset.svg');
				width:100%;
				height:100%;
		    }
		}
	}
	.quote-form-container {
		max-width: 400px;
		margin: 0 auto;
		padding: 30px 20px;
		background-color: #f8f8f8;
		border-radius: 5px;
	}
	.quote-form {
		text-align: center;
		.quote-input-container {
			display: block;
			max-width:280px;
			margin:0 auto;
			.quote-title {
				display: block;
				margin-bottom:5px;
			}
			label {
				font-weight: 600;
			}
			input[type="text"] {
				width: 100%;
				display: block;
				margin:10px auto 30px;
				border: 1px solid $primary;
				border-radius:6px;
				height:35px;
				padding:5px 10px;
				transition:$transition;
				&::placeholder {
					color:rgba($primary,1);
				}
				&:focus {
					outline:none;
					box-shadow: 4px 4px 0 #92c9c9,
								4px -4px 0 #92c9c9,
								-4px 4px 0 #92c9c9,
								-4px -4px 0 #92c9c9;
				}
			}
		}
		.btn {
			&:disabled {
				opacity: 0.5;
				cursor: default;
				animation: pulsate-fwd 1.5s ease-in-out infinite both;
			}
			&:not(:disabled) {
				background-color: $secondary;
				opacity: 1;
				animation: pulsate-fwd 0.5s ease-in-out infinite both;
				&:hover {
					background-color:#ff6e50;
				}
			}
			&:hover {
				background-color:$light-teal;
			}
		}
	}

	&.new {
		padding-top: 30px;
		.container {
			.btn {
				animation: pulsate-fwd 1.5s ease-in-out infinite both;
			}
		}
	}

	.security {
		margin-top: 5px;
		text-align: center;
		@include list();
		li {
			display: inline-block;
			font-size:14px;
			font-weight: 300;

			margin:11px 30px 11px 0;
			&:last-child {
				margin-right: 0;
			}
			i {
				margin-right:4px;
				font-size: 25px;
				vertical-align:-7px;
			}
		}
	}

	.quote-title_new {
		margin-bottom: 10px;
		color: $secondary;
		font-size: 18px;
		line-height: 26px;
		font-weight: 600;
		display: block;
		text-align: center;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-top:20px;
		padding-bottom:100px;

		.container {
			.cloud {
				top:-58px;
			    height: 100px;
			    width: 286px;
			}
			.sunset {
				bottom:-90px;
				height: 150px;
				width: 334px;
				.sun {
					bottom:74px;
					height: 92px;
					width: 261px;
				}
			}
		}
	
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}