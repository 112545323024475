.block.terms {
	padding-top:40px;
	padding-bottom:75px;

	.container {
		position: relative;
		.tombstone {
		    position: absolute;
		    bottom:-136px;
		    left: 0;
		    height: 105px;
		    width: 216px;
		    .stone {
				background: {
					repeat:no-repeat;
					position:center;
					image:url('../images/stone.svg');
				}
			    position: absolute;
			    left: 50%;
			    z-index: -1;
			    bottom: 8px;
			    height: 68px;
			    width: 159px;
			    transform:translateX(-50%);
		    }
		    .grass {
				background: {
					image:url('../images/tombstone.svg');
					repeat:no-repeat;
					size:cover;
					position: center;
				}
				position: absolute;
				width:100%;
				height: 100%;
		    }
		}
	}

	h2 {
		margin-bottom:10px;
		@include media-breakpoint-down(sm) {
			max-width: 70%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom:25px;
		}
	}

	.term {
		margin:15px 0;
		text-align: center;
		img, .description {
			max-width:203px;
			margin:0 auto;
			display: block;
		}
		.description {
			margin-top:20px;
			line-height:22px;
		}
		.term-link {
			display:inline-block;
			margin-top:15px;
			font-size:14px;
			font-weight: 500;
			color:$primary;
			text-decoration: none;
			&:hover {
				color:$secondary;
				transition:$transition;
			}
		}

	}

	.all-plans-link {
		text-align: center;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.container {
			.tombstone {
				height: 156px;
		    	width: 309px;
		    	bottom:-150px;
		    	.stone {
					height: 106px;
					width: 138px;
		    	}
			}
		}

		.term {
			margin:30px 0;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}