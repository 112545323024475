// Colours

$red: #f15a3c;
$blue: #4a5e6b;

$grey-dark: #424242;
$grey-med:#e8e7e7;
$grey-light: #f7f6f6;

$lightBrown: #d0c492;
$light-teal: #659292;

$primary: $blue;
$secondary: $red;

$error: $secondary;

$pColor: $primary;
$hColor: $primary;

// Fonts
$adelle: "adelle",serif;
$montserrat: 'Montserrat', sans-serif;

$fira: 'Fira Sans', sans-serif;
$oswald: 'Oswald', sans-serif;

$pFont: $fira;
$hFont: $oswald;

$h1Font: $adelle;

// Other
$transition: all 0.3s ease;

$utility-bar-height-desktop: 32px;

$header-mobile-height: 48px;
$header-desktop-height: 57px;
