.block.contact-block {

	padding-top:60px;

	.container {
		position: relative;
		&:after {
			content:'';
			background-image:url('../images/background-volcano.svg');
		    position: absolute;
		    bottom:-110px;
		    left:-25px;
		    right: 0px;
		    height: 90px;
		    width: 254px;
		}
	}

	h2 {
		margin-top:8px;
	}
	.image {
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	.details-list {
		@include list();
		li {
			position:relative;
			margin-bottom:20px;
			&.phone {
				&:before {
					content: "\"
				}
				.info {
					a {
						//font-weight: 300;
					}
				}
			}
			&.email {
				&:before {
					content: "\"
				}
			}
			&.twitter {
				&:before {
					content: "\"
				}
			}
			&.facebook {
				&:before {
					content: "\"
				}
			}
			&:before {
				@include iconFont;
				position:absolute;
				color:$secondary;
				font-size:39px;
				display: inline-block;
				left:0;
				top:50%;
				transform:translateY(-50%);
			}
			.info {
				display: inline-block;
				padding-left:55px;
				.title {
					font-weight: 600;
					display: block;
					font-size: 14px;
				}
				a {
					display: block;
					color:$primary;
					font-weight: 600;
					text-decoration: none;
					transition: $transition;
					&:hover {
						color:$secondary;
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.container {
			&:after {
			    left:-85px;
			}
		}

		.image {
			margin-top:-35px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}