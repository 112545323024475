.block.video-list {
	.container {
		position: relative;
		&:before {
			content: "";
			background-image: url("../images/background-tumbleweed.svg");
			position: absolute;
			top: -65px;
			right: -60px;
			height: 94px;
			width: 350px;
		}
		&:after {
			content: "";
			background-image: url("../images/background-mountain.svg");
			position: absolute;
			bottom: -35px;
			right: 0px;
			height: 87px;
			width: 286px;
		}
	}

	.video {
		margin: 35px 0;
		&.footer-callout {
			margin-top: -35px;
			.btn {
				padding: 5px 78px;
			}
		}
		&.centered {
			.row {
				height: 100%;
				.col-md-12 {
					padding: 30px 0;
					h2 {
						margin-top: 0;
					}
				}
			}
		}
	}
	.video-meta {
		padding: 15px 0;
		.watch {
			text-align: right;
		}
		.btn {
			padding: 5px 24.5px;
		}
	}

	h4.video-title {
		margin: 0;
		color: $secondary;
		font-weight: 500;
		font-family: $pFont;
		font-size: 20px;
	}

	.video-description {
		border-top: 1px solid #cac9c9;
		padding-top: 15px;
	}

	.video-thumb {
		padding-bottom: 56.25%;
		border-radius: 4px;
		border: 1px solid $light-teal;
		background: {
			size: cover;
			position: center;
			repeat: no-repeat;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.video-meta {
			.btn {
				padding: 5px 39.5px;
			}
		}

		.container {
			&:after {
				bottom: -40px;
				height: 140px;
				width: 545px;
				right:-100px;
			}
		}

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
		.container {
			&:after {
				bottom: 100px;		
			}
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
