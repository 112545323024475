body {
	
	main.main {
		position: relative;
		z-index: 1;
	}
	min-width: 320px;

	&.video-list-block {
		.featherlight-content {
			padding-bottom:43.7%;
			width:80%;
			overflow:hidden;
			background-color: transparent;
			iframe {
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
			}
		}
	}

	position: relative;
	&#tinymce {
		&:before {
			display: none;
		}
	}
	&:not(.wp-admin) {

	
	}


	.nothing-found	{
		text-align: center;
	}
	@include media-breakpoint-down(md) {

		.md-mb-2 {
			margin-bottom:20px;
		}

		.md-mb-4 {
			margin-bottom:40px;
		}
	}

	.wrap {
		overflow:hidden;
		position:relative;
		font-size: 16px;
		color: $pColor;
		font-family: $pFont;

		img {
			max-width: 100%;
			height: auto;
		}

		p a {
			color:$secondary;
			transition:$transition;
			text-decoration:none;
			&:hover {
				color:$primary;
			}
		}

		/* =============================================================================
		Extra Small +
		========================================================================== */ 
		@include media-breakpoint-up(xs) {

		} 

		/* =============================================================================
		Small +
		========================================================================== */ 
		@include media-breakpoint-up(sm) {

		}


		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {

		} 

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
		
		} 

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		
		}
	}
}