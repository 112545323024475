.block.slider-block-slick {
    padding:0;
    .slide-item {
        height: 400px;
        @include media-breakpoint-up(md) {
            height: 500px;
        }
        @include media-breakpoint-up(lg) {
            height: 600px;
        }
        @include media-breakpoint-up(xl) {
            height: 750px;
        }
        &.home-banner-container {
            .steps {
                padding-bottom:40px;
            }
        }
        h1 {
            margin: 0;
            font-size: 36px;
            color: $secondary;
            text-transform: uppercase;
            font-weight: 800;
            text-shadow: 1px 1px 0 rgba($primary,0.3);
            line-height: 40px;
            @include media-breakpoint-up(md) {
                font-size:50px;
                line-height: 58px;
            }
        }

        .wysiwyg {
            font-weight: bold;
            margin:10px 0;
            font-size:20px;
            font-family: $hFont;
        }

    }
    .slide-item .slide-content {
        height:100%;
        width:100%;
        background: {
            size:cover;
            repeat:no-repeat;
        }
        position:relative;
        &.top {
            background-position:top center;
        }
        &.center {
            background-position: center;
        }
        &.bottom {
            background-position: bottom center;
        }
        &.has-video {
            padding-bottom:0;
            min-height: 0;
        }
        .content {
            padding: 0 30px;
        }
        .poster {
            width:100%;
            height:100%;
            .play-video {
                font-size:75px;
                &:hover {
                    font-size:78px;
                }
            }
        }
        .container {
            height: 100%;
            position:absolute;
            left: 50%;
            transform: translateX(-50%);
            .row {
                position: absolute;
                top: 50%;
                transform:translateY(-50%);
                width:100%;
                @include media-breakpoint-up(md) {
                    &.left {
                        left:50px;
                    }
                    &.right {
                        right:50px;
                    }
                    &.center {
                        text-align: center;
                    }
                }
                .btn {
                    margin-top:15px;
                    @include media-breakpoint-up(md) {
                        margin-top:35px;
                    }
                }
                @include media-breakpoint-up(md) {

                }
            }
        }
    }
}

.slick-dots {
    position: absolute;
    bottom: 30px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0;
    li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border-radius: 100%;
            border: 1px solid #9b9b9b;
            background: #fff;
            display: block;
            height: 12px;
            width: 12px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            transition: 0.3s;
            &:hover,
            &:focus {
                background: #9b9b9b;
                outline: none;
                &:before {
                    opacity: 1;
                }
            }
        }

        &.slick-active {
            button {
                background: #9b9b9b;
            }
        }
    }

    @include media-breakpoint-up(md) {
        li {
            height: 16px;
            width: 16px;
            margin: 0 7px;
            button {
                height: 19px;
                width: 19px;
            }

            .slick-small-dots & {
                margin: 0 12px;
                height: 15px;
                width: 15px;

                button {
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }
}

.slick-arrow {
    position: absolute;
    top: 50%;
    border: none;
    transform: translateY(-50%);
    background: none;
    color: $grey-light;
    font-size: 0;
    opacity: 1;
    transition: 0.3s;
    z-index: 20;
    width: 45px;
    padding: 0;
    margin: 0;
    text-shadow:0px 0px 2px #9b9b9b, 0px 0px 1px #9b9b9b;

    &.slick-disabled {
        opacity: 0.4;

        &:hover {
            opacity: 0.4;
        }
    }

    &:hover {
        color: $secondary;
        cursor: pointer;
    }

    &:active,
    &:focus {
        outline: none;
        border: none;
    }

    &:before {
        @include iconFont;
        font-size: 50px;
        text-align: center;
    }

    &.slick-prev {
        left: 0px;

        &:before {
            content: "\E010";
        }
    }
    &.slick-next {
        right: 0px;

        &:before {
            content: "\E011";
        }
    }

    @include media-breakpoint-up(md) {
        &.slick-prev {
            left: 15px;
        }
        &.slick-next {
            right: 15px;
        }
    }
}


