.site-header {
	
	position: fixed;
	z-index: 100;
	top: 0;
	width: 100%;

	@media screen and (max-width:783px) {
		.admin-bar & {
			top: 46px;
		}
	}

	.site-info {
		max-height: $header-mobile-height;
		transition: $transition;
		background-color:rgba(255,255,255,0.9);
		padding:11px 0;
		.menu-open & {
			background-color:rgba(255,255,255,1);
		}
		.row {
			padding:0 15px;
		}

		.brand {
			img {
				max-width: 90px;
				width:100%;
				display:block;
			}
		}

		.customer-service {
			a {
				text-decoration: none;
				color: $primary;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 12px;
				transition:color 0.3s ease;
				&:hover {
					color:$secondary;
					i {
						transform:scale(1.2);
					}
				}
				i {
					transition:transform 0.3s ease;
					display: inline-block;
					font-size: 15px;
					vertical-align: -2px;
					margin-right: 4px;					
				}
			}
		}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.admin-bar & {
			margin-top: calc(32px);
		}

		.menu-trigger {
			display: none;
		}

		.site-info {
			padding:8px 0 0;
			max-height: calc(8px + #{$header-desktop-height});
			.row {
				height: $header-desktop-height;
				transition:$transition;
				.shrink & {
					//height:65px;
				}
			}

			.brand {
				position:relative;
				img {
					max-width: 133px;
					padding-bottom:8px;
				}
				&:before {
					content:'';
					position: absolute;
					width:0;
					height: 4px;
					background-color:$secondary;
					transition: $transition;
					bottom:-4px;
					left:50%;
					transform:translateX(-50%);
					.home & {
						width: 110%;
					}
				}
				&:hover {
					&:before {
						width: 110%;
					}
				}
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}