.block.home-banner {
	padding-bottom:0px;
	background: {
		repeat:repeat;
		position:center;
		color: transparent;
		size:contain;
	}
	position:relative;
	.container {
		position: relative;
		text-align: center;
		@include media-breakpoint-down(sm) {
			max-width:100%;
			.inner {
				display: inline-block;
			}
			.quote-title {
				display: block;
				margin:0 auto;
			}
		}
		.cloud {
			background-image:url('../images/clouds.svg');
		    position: absolute;
		    top:5px;
		    right: 0;
		    height: -69px;
		    width: 209px;
		}
	}
	.title-container {
		text-align: center;
		display: block;
		margin:0;
		h1 {
			&.first-title {
				font-size: 33px;
			}
			margin:0;
			font-size:26px;
			color:$secondary;
			text-transform: uppercase;
			font-weight: 800;
			text-shadow: 1px 1px 0 #CECECE;
			line-height: 31px;
		}
	}
	&.original {
		.steps-container {
			position: relative;
			z-index: 1;
			&:before {
				content:'';
				background-color: #fff;
				position: absolute;
				left: 50%;
				transform:translateX(-50%);
				top:0;
				width:calc(100% + 30px);
				height:100%;
			}
		}
		.quote-title {
			padding-top: 5px;
			position: relative;
			text-align: center;
			font-size: 17px;
			font-weight: 600;
			display: inline-block;
			z-index: 2;
		}
		.inner {
			text-align: center;
		}
		.steps {
			position: relative;
			z-index: 2;
			text-align: left;
			@include list();
			counter-reset: section;
			margin-top:20px;
			margin-bottom:20px;
			display: inline-block;
			.step {
				margin-bottom:13px;
				color:$secondary;
				font-size:18px;
				font-weight: 400;
				&:before {
					font-family:$oswald;
					font-size:14px;
					margin-right:9px;
					display: inline-block;
					font-weight: 600;
					text-align:center;
					border-radius:50%;
					width:24px;
					height:24px;
					line-height:24px;
					background-color:$primary;
					color:#fff;
					counter-increment: section;
					content: counters(section, ".") " ";
				}
			}
		}
	}

	&.new {
		padding-top: 30px;
		.quote-title {
		    font-size: 25px;
		    line-height: 30px;
		    font-family:$oswald;
		    font-weight: 500;
		}
		.inner {
			text-align: center;
		}
		.steps {
			text-align: left;
			max-width: 400px;
			margin-left: auto;
			margin-right: auto;
			li {
				font-weight: 400;
				margin-bottom: 15px;
				font-size: 16px;
				line-height: 20px;
			}
		}
	}

	.desktop-image {
		display: none;
	}
	.mobile-image {
		display: block;
		img {
			max-width:315px;
			width:100%;
			margin:0 auto;
			display: block;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding-top:40px;
	
		.container {
			text-align: left;
			.title-container {
				h1 {
					&.first-title {
						font-size:42px;
						line-height:47px;
					}
					&.second-title {
						font-size: 33px;
						line-height: 47px;
					}

				}
			}
			.cloud {
			    top:-35px;
				height: 100px;
				width: 286px;
			}
		}
		.desktop-image {
			display: block;
			position:absolute;
			max-width:315px;
			width:100%;
			bottom:0;
			margin-left: -40px;
		}
		.mobile-image {
			display: none;
		}

		.steps-container {
			&:before {
				display: none;
			}
		}
		.quote-title {
			padding-top: 20px;
			display: block;
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {


		.quote-title {
			padding-top: 35px;
		}
		.container {
			.cloud {
			    top:-30px;
			}
			.title-container {
				h1 {
					&.first-title, &.second-title {
						font-size:42px;
						line-height:53px;
					}

				}
			}
		}
		.desktop-image {
			margin-left: 0;
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}