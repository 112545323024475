.block.faq {

	padding:30px 0 50px;

	h2.section-title {
		text-align: left;
		margin-top:40px;
		&:first-of-type {
			margin-top: 0;
		}
	}

	.item {
		padding-right: 0px;
		margin-bottom:15px;
		.question {
			text-decoration: none;
			color: #D24F35;
			font-weight: 800;
			position: relative;
			font-size: 20px;
			line-height: 1.5em;
			transition:	$transition;
			display: block;
			padding-bottom:10px;
			margin-bottom:15px;
			border-bottom: 0.5px solid #cac9c9;
			span {
				padding-right:30px;
				display:block;
			}
			&:hover {
				color: $primary + 40;
			}
			&:before {
				@include iconFont;
				content:'\e004';
				position: absolute;
				top: 50%;
				right: 0px;
				color: #92c9c9;
				font-size: 24px;
				transition: $transition;
				transform: translateY(-50%) rotate(90deg);
			}
			&.active {
				&:before {
					transform: translateY(-50%) rotate(-90deg);
				}
			}

		}

		.answer {
			p {
				font-size: 19px;
				&:first-of-type {
					margin-top: 5px;
					padding-top: 0;
				}
			}
			transition: $transition;
			max-height: 0;
			overflow:hidden;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}