.single {

	margin-top: calc(8px + #{$header-mobile-height});

	.admin-bar & {
		margin-top: calc(40px + #{$header-mobile-height});
	}

	article.post {
		margin-top:120px;
		margin-bottom:150px;
		a {
			font-weight: 500;
			color:$primary;
			text-decoration:none;
			transition:$transition;
			&:hover {
				color:$secondary;
			}
		}
		header {
			img {
				border-radius:4px 4px 0 0;
			}
		}
		.entry-title {
			color:$secondary;
		}

		.widget {
			h3 {
				color:$light-teal;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		margin-top: calc(8px + #{$header-desktop-height});
		article {
			.entry-title {
				font-size: 40px;
				border-bottom:1px solid #dadada;
				padding-bottom:30px;
			}
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}