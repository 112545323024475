.single-career {

	article {
		padding-top: 30px;
		.header {
			position: relative;
			&:after {
				content:'';
				background-image:url('../images/background-rocks.svg');
			    position: absolute;
			    top:111px;
			    right: 0px;
			    height: 71px;
			    width: 246px;
			}
		}

		.section-title {
			text-align: center;
			padding-top:25px;
			display: block;
			padding-bottom:30px;
			font-weight: 400;
		}
	
		.back-button {

			a {
				position:relative;
				text-decoration: none;
				transition:$transition;
				padding-left:22px;
				font-weight: 500;
				color:$primary;
				&:hover {
					color:$secondary;
				}
				&:before {
					top:1px;
					color:#92c9c9;
					left:0px;
					font-size:17px;
					@include iconFont;
					content: "\";
					position:absolute;
					transform:rotate(180deg);
				}
			}
		}

		.career-info {
			margin-top: 90px;
			.career-title {
				font-size:20px;
				font-weight: 800;
				line-height:26px;
				color:$secondary;
				font-family: $pFont;
				margin-bottom:0;
			}
			.location {
				font-weight: 600;
				color:$primary;
				line-height:23px;
				display: block;
				border-bottom: 0.5px solid #cac9c9;
				padding-bottom:5px;
				
			}
			.meta {
				margin-top:5px;
				margin-bottom:25px;
				text-align: center;
				font-size:14px;
				span, time {
					display: inline-block;
				}
			}
			strong {
				color:#272727;
			}
		}
		.description {
			line-height: 22px;
			h1,h2,h3,h4,h5,h6 {
				font-family: $pFont;
			}
		}
		.application-form {
			max-width: 650px;
			margin:25px auto 50px;
			.title {
				color:#484848;
				font-weight: 700;
			}
		}

		/* =============================================================================
		Extra Small +
		========================================================================== */ 
		@include media-breakpoint-up(xs) {

		} 

		/* =============================================================================
		Small +
		========================================================================== */ 
		@include media-breakpoint-up(sm) {

		}


		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {


			article {
				.header {
					&:after {
					    top:65px;
					    height: 123px;
					    width: 427px;
					}
				}
				.career-info {
					margin-top: 40px;
				}
			}
		
			

		} 

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
		
		} 

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		
		}
	}
}