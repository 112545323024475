.footer-content {
	font-family: $pFont;
	background-color:$grey-med;
	padding-bottom:20px;
	margin-top:100px;
	text-align: center;
	.main-footer {
		padding:75px 0 40px;
		position: relative;
		&:before {
			content:'';
			background: {
				image:url('../images/footer_mobile.svg');
				position: bottom;
				repeat-y: no-repeat;
				size: 100%;
			}
		    position: absolute;
		    left: 0;
		    width: 100%;
		    top:5.3vw;
		    height: 37vw;		    
		    transform: translateY(-91%);
		}
		.btn {
			padding-left:5px;
			padding-right: 5px;
			width:190px;
			max-width:100%;
			text-align: center;
		}
		.corporate-address {
			display: block;
			margin-top: 10px;
		}
		.mobile-phone {
			display:block;
			margin-top:10px;
			font-weight: bold;
			a {
				text-decoration: none;
				color:$pColor;
			}
		}
		.company-menu, .help-menu {
			text-align: left;
			h4 {
				font-family: $pFont;
				font-weight: 400;
				margin:0 0 15px;
			}
			ul {
				@include list();
				li {
					a {
						font-weight: 500;
						color:$primary;
						text-decoration: none;
						line-height: 23px;
						transition:$transition;
						&:hover {
							color:$secondary;
						}
					}
				}
			}
		}
	}

	.socket {
		padding: 12px 0;
		text-align: center;
		text-align:center;
		.nav {
			@include list();
			display: inline-block;
			li {
				display: inline-block;
				margin:0px 35px;
				margin-bottom: 0;
				a {
					font-weight: 500;
					color:$primary;
					text-decoration: none;
					transition:$transition;
					&:hover {
						color:$secondary;
					}
				}
			}
		}
		.copyright {
			display: block;
			margin-top:2px;
			font-size: 12px;
		}
	}
	
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		text-align: left;
		.main-footer {
			.mobile-phone {
				display: none;
			}
		}
		.socket {
			text-align: initial;
			[class^='col-'] {
				text-align: center;
			}
			.nav {
				text-align: center;
			}
			.attribution {
				text-align: right;
			}
			.copyright {
				display: inline-block;
				margin-top:0;
			}
		}

		.main-footer {
			&:before {
				background: {
					image:url('../images/footer.svg');
					position:center;
					size: auto;
				}
				top: 0;
			    height: 16vw;
			    transform:translateY(-82%);
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
		.main-footer {
			&:before {
			    height: 153px;
			    top:-125px;
			    transform:none;
			}
		}

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}