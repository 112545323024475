.block.learn-callout {
	padding-bottom:90px;
	padding-top:110px;
	overflow: hidden;
	.container {
		position: relative;
		&:before {
			content:'';
			background-image:url('../images/background-tumbleweed.svg');
		    position: absolute;
		    top:-85px;
		    right: -60px;
		    height: 94px;
		    width: 350px;
		}
		&:after {
			content:'';
			background-image:url('../images/background-mountain.svg');
		    position: absolute;
		    bottom:-85px;
		    right: 0px;
		    height: 87px;
		    width: 286px;
		}
	}

	h2 {
		margin:5px 0 70px 0;
	}
	
	.callout {
		margin-bottom:50px;
		h3 {
			font-family: $pFont;
			color:$secondary;
			font-weight: 800;
			padding-bottom:10px;
			margin:0 0 20px 0;
			border-bottom: 0.5px solid $grey-med - 30;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.callout {
			margin-bottom:70px;
		}

		.container {
			&:after {
			    bottom:-85px;
			    right: -100px;
			    height: 140px;
			    width: 545px;
			}
		}
	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}